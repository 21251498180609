const useTextModifiers = () => {
  const DataSourceStore = useDataSourceStore()
  const decodeHtmlEntity = (str: string) => {
    return str && str !== null
      ? str.replace(/&#(\d+);/g, (_match, dec) => String.fromCharCode(dec))
      : str
  }

  const re = new RegExp(
    Object.keys(DataSourceStore.dataSourceValues).join('|'),
    'g',
  )
  const replaceDataSourceValues = (str: string) => {
    return str && str !== null
      ? str.replace(
          re,
          (matched) => DataSourceStore.dataSourceValues[matched as keyof {}],
        )
      : str
  }
  const decodeAndReplaceText = (str: string) =>
    replaceDataSourceValues(decodeHtmlEntity(str))

  return {
    replaceDataSourceValues,
    decodeHtmlEntity,
    decodeAndReplaceText,
  }
}
export default useTextModifiers
